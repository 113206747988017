import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
    //@ts-expect-error
    "app/theme.ts"
  );
  import.meta.hot.lastModified = "1698839577000";
}
const theme = {
  colorScheme: "light"
};
export {
  theme
};
