
        if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
          console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
        } else {
          var prevRefreshReg = window.$RefreshReg$;
          var prevRefreshSig = window.$RefreshSig$;
          window.$RefreshReg$ = (type, id) => {
            window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
          }
          window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
        }
      import { jsx, jsxs } from "react/jsx-runtime";
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
  import.meta.hot.lastModified = "1698839577000";
}
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import { StylesPlaceholder } from "@mantine/remix";
import { theme } from "./theme";
const meta = () => {
  return [{
    title: "SFS CCMS"
  }, {
    name: "description",
    content: "Welcome to Remix!"
  }];
};
createEmotionCache({
  key: "mantine"
});
function App() {
  return /* @__PURE__ */jsx(MantineProvider, {
    theme,
    withGlobalStyles: true,
    withNormalizeCSS: true,
    children: /* @__PURE__ */jsxs("html", {
      lang: "en",
      style: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
      },
      children: [/* @__PURE__ */jsxs("head", {
        children: [/* @__PURE__ */jsx("meta", {
          name: "viewport",
          content: "width=device-width, initial-scale=1"
        }), /* @__PURE__ */jsx(StylesPlaceholder, {}), /* @__PURE__ */jsx(Meta, {}), /* @__PURE__ */jsx(Links, {})]
      }), /* @__PURE__ */jsxs("body", {
        style: {
          flexGrow: 1
        },
        children: [/* @__PURE__ */jsx(Outlet, {}), /* @__PURE__ */jsx(ScrollRestoration, {}), /* @__PURE__ */jsx(Scripts, {}), /* @__PURE__ */jsx(LiveReload, {})]
      })]
    })
  });
}
_c = App;
export { App as default, meta };
var _c;
$RefreshReg$(_c, "App");
        window.$RefreshReg$ = prevRefreshReg;
        window.$RefreshSig$ = prevRefreshSig;
      